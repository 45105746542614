import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomeImprovements = () => (
  <Layout>
    <SEO
      title="Home Improvements"
      description={`It's the small things that can make all the difference. A new deck, a straight and true fence, insulation added to walls or a new skylight to lighten a hallway.`}
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Home Improvements</h1>
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <p>
                It's the small things that can make all the difference
                sometimes. A new deck, a straight and true fence, insulation
                added to walls or a new skylight to lighten a hallway.
              </p>
              <p>
                If you have a small job you want done right, get in touch for a
                free consultation and sound building advice.
              </p>
              <Link to="/contact/" className="btn btn-primary mt-3">
                Book free consultation
              </Link>
            </div>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 mb-4">
            <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 12}
                  transformOptions={{ cropFocus: "bottom" }}
                  src="../images/home-improvements/marty-reclad/1.webp"
                  alt="Recladding"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 12}
                  src="../images/home-improvements/marty-reclad/2.webp"
                  alt="Recladding"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 12}
                  src="../images/home-improvements/marty-reclad/3.webp"
                  alt="Recladding"
                  loading="eager"
                />
              </Carousel.Item>

              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 12}
                  src="../images/home-improvements/deck.jpeg"
                  alt="Covered deck"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default HomeImprovements
